import { createAction } from 'redux-actions'
import {
  FETCH_CD_OPTIONS,
  FETCH_CD_OPTIONS_ERROR,
  FETCH_CD_OPTIONS_SUCCESS,
  FETCH_TICKET_LIST,
  FETCH_TICKET_LIST_ERROR,
  FETCH_TICKET_LIST_SUCCESS,
  SET_TICKET_LIST_FILTER,
  CLEAR_TICKET_LIST_FILTER,
} from './constants'

export const fetchChangeDetectionOptions = createAction(FETCH_CD_OPTIONS)
export const fetchChangeDetectionOptionsError = createAction(
  FETCH_CD_OPTIONS_ERROR
)
export const fetchChangeDetectionOptionsSuccess = createAction(
  FETCH_CD_OPTIONS_SUCCESS
)

export const fetchTicketList = createAction(FETCH_TICKET_LIST)
export const fetchTicketListError = createAction(FETCH_TICKET_LIST_ERROR)
export const fetchTicketListSuccess = createAction(FETCH_TICKET_LIST_SUCCESS)

export const setTicketListFilter = createAction(SET_TICKET_LIST_FILTER)

export const clearTicketListFilter = createAction(CLEAR_TICKET_LIST_FILTER)
