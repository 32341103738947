import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import featureFlags from 'containers/FeatureFlags'
import Breadcrumb, { BreadcrumbItem } from 'components/Breadcrumb'
import TextLink from 'components/TextLink'
import BarLoadingAnimation from 'components/BarLoadingAnimation'
import { getTicketId } from 'utils/sonraiUtils'
import { fetchTicketDetails } from 'containers/TicketDetailsData/actions'
import { fetchChangeDetectionOptions } from 'containers/Tickets/actions'
import {
  selectControlGroups,
  selectPolicies,
} from 'containers/ControlFrameworkData/selectors'
import {
  selectQueryStringTicketSrn,
  selectTicketDetails,
} from 'containers/TicketDetailsData/selectors'
import Ticket from './Ticket'

class TicketDetails extends React.Component {
  constructor(props, context) {
    super(props, context)
    props.fetchChangeDetectionOptions()

    this.style = {
      container: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto 1fr',
        padding: '1rem',
        height: '100%',
        overflow: 'auto',
      },
    }
  }

  render() {
    if (this.props.controlGroups.isEmpty() || this.props.policies.isEmpty()) {
      return (
        <div style={this.style.loadingContainer}>
          <BarLoadingAnimation />
        </div>
      )
    }

    return (
      <div style={this.style.container}>
        {/*  Breadcrumbs  */}
        <div style={{ padding: '0rem 0rem 1rem 0rem' }}>
          <Breadcrumb>
            <BreadcrumbItem>
              <TextLink color="primary" to={{ pathname: '/App/Tickets' }}>
                All Tickets
              </TextLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              Ticket #{' '}
              {getTicketId(
                this.props.ticketDetails.getIn(['data', 'resourceId'])
              )}
            </BreadcrumbItem>
          </Breadcrumb>
        </div>

        <Ticket key={this.props.ticketSrn} ticketSrn={this.props.ticketSrn} />
      </div>
    )
  }
}

TicketDetails.propTypes = {
  controlGroups: ImmutablePropTypes.map,
  ticketDetails: ImmutablePropTypes.contains({
    loading: PropTypes.bool,
    error: PropTypes.bool,
    data: ImmutablePropTypes.map,
  }),
  ticketSrn: PropTypes.string,
  policies: ImmutablePropTypes.map,
  fetchChangeDetectionOptions: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  controlGroups: selectControlGroups,
  policies: selectPolicies,
  ticketDetails: selectTicketDetails,
  ticketSrn: selectQueryStringTicketSrn,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchTicketDetails,
      fetchChangeDetectionOptions,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(featureFlags, withConnect)(TicketDetails)
